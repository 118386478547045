@import '../../scss/components_base';

.container {
  @include body;
  display: none;
  display: block;

  @include grid-standard;
  @include md {
  }
  // @include grid-standard;
}
.mainItems {
  grid-column-start: 2;
  grid-column-end: -2;

  width: 100%;

  @include md {
    display: flex;
    grid-column-end: -1;
    // leave room for cookie button
    margin-left: 1%;
    padding-left: 50px;
  }

  > :first-child {
    margin-top: 120px;

    @include md {
      margin-top: 0;
    }
  }
}
.mainItemsSmallMargin {
  > :first-child {
    margin-top: 7.5vh;

    @include md {
      margin-top: 0;
    }
  }
}
.bottomItems {
  font-style: normal;
  font-weight: normal;
  font-family: var(--font-primary);
  font-size: 13px;
  line-height: 1.3;
  letter-spacing: 0.2px;
  margin-top: 10px;
  margin-bottom: 25px;
  grid-column-start: 2;
  grid-column-end: -2;

  @include md {
    font-size: max(rem(15), 13px);
    grid-column-start: 1;
    grid-column-end: -2;
    margin-left: rem(20);
    margin-bottom: rem(20);
  }
}
.bottomLink {
  @include unstyled-link;
  margin-left: rem(20);
}
.cookieSettingsLink {
  cursor: pointer;
}
.menuList {
  margin-bottom: rem(19);
  @include md {
    margin-left: auto;
  }
}
.officeList {
  @include list-unstyled();
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @include md {
    margin-bottom: 0;
    flex-direction: row;
    justify-content: center;
  }
}
.adressListIsHidden {
  opacity: 0;
  visibility: hidden;
}
.officeListItem {
  margin-bottom: 33px;
  @include md {
    margin-bottom: 0px;
    margin-right: rem(120);
    &:last-child {
      margin-right: 0;
    }
  }
}
.officeListItemAddressShort {
  display: none;
  @include md {
    display: inline;
  }
}
.officeListItemAddressFull {
  @include md {
    display: none;
  }
}
.socialList {
  @include list-unstyled();
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  @include md {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
    margin-right: rem(38);
    margin-left: auto;
  }
}
.socialListIsHidden {
  display: none;
}
.socialListIsShowing {
  // transform: translateY(0);
}
.socialListItem {
  font-style: normal;
  font-weight: normal;
  font-family: var(--font-primary);
  font-size: 13px;
  line-height: 1.3;

  letter-spacing: 0.2px;

  text-transform: capitalize;
  margin-right: 20px;

  @include md {
    font-size: max(rem(15), 13px);
    text-align: right;
    margin-right: rem(20);
    &:last-child {
      // margin-right: 0;
    }
  }
}
