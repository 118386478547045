@import '../../scss/components_base';

.container {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  opacity: 0;

  z-index: 9;
  // pointer-events: none;
  mix-blend-mode: multiply;
  background: var(--color-dark-red);
}

.logoContainer {
  position: fixed;
  z-index: 11;
  display: none;
  opacity: 0;
  top: 0;
  left: 0;
  padding-top: rem(100);
  padding-bottom: rem(144);
  // transform: translateX(-50%);
  width: 100%;
  height: 100%;
  @include md {
    padding-top: rem(218);
    padding-bottom: rem(237);
  }
}
.svg {
  height: 100%;
  width: 100%;
  path {
    fill: var(--color-red);
  }
}
