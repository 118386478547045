@import '../../scss/components_base';

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 900;
  // pointer-events: none;
  background: linear-gradient(var(--color-dark-red), var(--color-dark-red));
  @include md {
    mix-blend-mode: multiply;
  }
}

.colourObscure {
  // background: var(--color-dark-red);
  // width: 100%;
  // height: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 1000;
}

.logoContainer {
  position: absolute;
  z-index: 1100;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  svg {
    path {
      fill: var(--color-red);
    }
  }

  @include md {
    padding-top: rem(218);
    padding-bottom: rem(237);
  }
}
