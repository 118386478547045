@import './utilities/layout';
@import './utilities/show-hide';

// UPDATE_ME:
// You _may_ opt to add more utility classes here.
// e.g. spacing, display properties, font utilities (size, weight etc).
// Please note these can get pretty big, especially responsive grid utilities,
// so only add what is necessary.

.is-scroll-disabled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.u-stop-bounce {
  @include md {
    height: 100%;
    overflow: hidden;
  }
}
.u-isFreeToScroll {
  position: absolute !important;
  overflow: hidden !important;
}
