@import '../../scss/components_base';

.menuList {
  @include list-unstyled;
  pointer-events: all;
  @include md {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    margin-right: rem(38);
    grid-column-start: 14;
    grid-column-end: -1;
    grid-row-start: 1;
    margin-top: rem(31);
  }
}
.menuListItem {
  @include body;
  display: inline-block;
  @include md {
    margin-right: rem(20);
  }
}

.menuListAtBottom {
  @include md {
    margin-top: 0;
    margin-bottom: rem(0);
  }
}

.link {
  @include unstyled-link();
}
.linkIsSelected {
  color: var(--color-rollover);
}
