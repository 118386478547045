@import '../../scss/components_base';

.container {
  display: flex;
  flex-direction: column;
  svg {
    // height: 100%;
    path {
      fill: black;
    }
  }
}
.desktop {
  width: 100%;
}
.fitWidth {
  width: 100%;
  height: auto;
}
.fitHeight {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
}
