@import '../../scss/components_base';

.container {
  font-family: var(--font-primary-alt1);
  font-size: 16px;
  position: fixed;
  right: 0;
  top: 50%;
  white-space: nowrap;
  z-index: 3;
  height: 3.3em;
}
.text {
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  padding: 1.1em;
  background: black;
  color: white;
  font-weight: 700;
  letter-spacing: 0.1em;
  transform: rotateZ(90deg) translate3d(3.3em, 0, 0);
  transform-origin: top right;
}
.exit {
  text-align: center;
  float: right;
  width: 3.3em;
  background: white;
  border: 1px solid black;
}