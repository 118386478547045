// regular
@font-face {
  font-family: 'Founders Grotesk';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/founders-grotesk-light.woff2') format('woff2');
}
@font-face {
  font-family: 'Founders Grotesk';
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/FoundersGroteskWeb-Semibold.woff2') format('woff2');
}
@font-face {
  font-family: 'Founders Grotesk';
  font-style: italic;
  font-weight: bold;
  src: url('/fonts/FoundersGroteskWeb-SemiboldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Founders Grotesk';
  font-style: italic;
  font-weight: normal;
  src: url('/fonts/founders-grotesk-light-italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Founders Grotesk Mono';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/founders-grotesk-mono-light.woff2') format('woff2');
}
// condensed
@font-face {
  font-family: 'Founders Grotesk X-Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/founders-grotesk-x-condensed-semibold.woff2') format('woff2');
}
// serif
@font-face {
  font-family: 'GT Alpina Fine';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/GT-Alpina-Fine-Condensed-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'GT Alpina Fine';
  font-style: italic;
  font-weight: normal;
  src: url('/fonts/GT-Alpina-Fine-Condensed-Light-Italic.woff2') format('woff2');
}
