@import '../../scss/components_base';

.row {
  @include md {
    display: flex;
    position: relative;
    flex-direction: column;
    width: var(--vw);
    flex: 1;
  }
}

.test {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 99;
  background-color: var(--color-red);
}

.slidesContainer {
  z-index: 1;
  position: relative;
  // grid-column-start: 1;
  // grid-column-end: -1;
  // grid-row-start: 2;
  @include md {
    // overflow: hidden;
    display: flex;
    height: 100%;
    flex: 1;
  }
}
.slidesInner {
  flex: 1;
  position: relative;
  height: var(--slideHeight);
  // overflow: hidden;
}
.slideImage {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.slideTitleContainer {
  z-index: 1;
}
.slideTitle {
  @include carousel-heading;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  padding: 0;
  width: 100%;
  // padding-left: rem(10);
  // padding-right: rem(10);
  padding-top: rem(30);
  text-align: center;
  z-index: 1;
  overflow-wrap: anywhere;
}
.slideDesktopTitle {
  display: block;
  @include tabletOnly {
    display: none;
  }
}

.slideTabletTitle {
  @include carousel-heading;
  display: none;
  text-transform: uppercase;
  width: 100%;
  padding-top: rem(30);
  text-align: center;
  @include tabletOnly {
    display: block;
  }
}
.scaleContainer {
  flex: 1;
  position: relative;
  height: 100%;
  z-index: 2;
}
.slide {
  cursor: pointer;

  width: var(--slideWidth);
  height: 100%;
  position: absolute;
  display: flex;
  user-select: none;
  padding-right: var(--slideMargin);
  // dev note: check https://medium.com/@christopherjfoster/aspect-ratio-media-queries-3ad54ba64611
}
.slideInner {
  position: relative;
  // width: 100vw;
  @include md {
    flex: 1;
  }
}
