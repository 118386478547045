:root {
  --standard-cols: 14;
  --standard-gap: 0px;
  --page-gutter: 20px;

  --vw: 100vw;
  --vh: 100vh;

  // default settings for padding/margins
  --space-small: #{rem(10)};
  --space: #{rem(12)};
  --space-medium: #{rem(24)};
  --space-large: #{rem(40)};
  --space-x-large: #{rem(48)};
  --space-xx-large: #{rem(52)};
  // we need this to be a var as we use it in some diff places
  // has to be MS
  --pageTransitionTime: 200ms;
  --pageTransitionDelay: 350ms;
  // in seconds
  --projectIntroTime: 750ms;
  // this has to be a VW measurement as it's also used in JS for better performance
  --slideWidth: 100px;
  --slideHeight: 100px;
  --slideMargin: 31px;

  --contentImageHeight: 20px;
  // 1400 139 are the logo dimensions
  --logo-ratio: 0.099285714285714;
  --logo-padding: 18px;
  --carousel-top: 0;

  --page-padding-top: 50px;
  --galleryBlock-slide-height: 90vh;

  @include md {
    --standard-cols: 16;
    --pageTransitionTime: 500ms;
    --pageTransitionDelay: 750ms;
    --standard-gap: #{rem(17)};
    --page-gutter: #{rem(91)};
    --space-x-large: #{rem(64)};
    --space-xx-large: #{rem(100)};

    // this has to be a VW measurement as it's also used in JS for better performance
    --slideWidth: 35vw;
    --slideHeight: 55vh;
    --slideMargin: #{rem(31)};

    --page-padding-top: #{rem(119)};
    --contentImageHeight: calc(100vh - var(--page-padding-top) - var(--page-padding-top));

    // 1400 139 are the logo dimensions
    --logo-ratio: 0.099285714285714;
    --logo-padding: #{rem(18)};
    --carousel-top: calc(
      (100vw - var(--logo-padding) * 2) * var(--logo-ratio) + var(--logo-padding) * 2
    );
  }

  @include customTablet {
    // this will change the slides to be square sized
    --slideHeight: calc(var(--slideWidth) - var(--slideMargin));
  }
}
