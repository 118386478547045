// =============================================================================
// Custom properties (variables) for custom colors, including the brand colors,
// primary and secondary palettes.
// =============================================================================

:root {
  --color-primary: black;
  --color-rollover: #bababa;
  --color-dark-red: #860e0f;
  --color-red: #ff0302;

  --logo-color: black;
  // only show rollovers on media that supports it
  @media (hover: hover) {
  }
}
