@use 'sass:math';
$root-font-base-size: 14px;
$root-font-max-size: 14px;
$design-width: 1440px;

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern=1';
  -webkit-font-kerning: normal;
  -moz-font-kerning: normal;
  font-feature-settings: 'kern';
  font-kerning: normal;

  font-size: 10px;

  @include md {
    @include vw-font-size(
      $root-font-base-size,
      $min-px-size: math.div($root-font-base-size * 320px, $design-width),
      $max-px-size: $root-font-max-size,
      $as-at: $design-width
    );
  }
}

body {
  @include body();
}

h1 {
  @include heading-x-large;
}
h2 {
  @include heading-medium;
}
h3 {
  @include heading-small-light;
}
h4 {
  @include heading-small-light;
}

// UPDATE_ME
p {
  padding-bottom: var(--space);
}
