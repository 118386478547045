@import '../../scss/components_base';

.stickyScrollContainer {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  // background: red;
  height: 100%;
  @include md {
    justify-content: center;
    align-items: unset;
  }
}
.stickyScrollToggleContainer {
  // posit
  order: 2;
  position: absolute;
  top: 20px;
  right: 20px;
}
.stickyScrollLogo {
  padding-top: 15px;
  width: 200px;
  // padding-left: 100px;
  @include md {
    display: none;
  }
}

.gridContainer {
  @include md {
    @include grid-standard;
    grid-template-rows: max-content;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 5;
    // position: sticky;
    // height: 1px;
  }
}

.menuContainer {
  display: none;
  @include md {
    display: block;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: -1;
  }
}

.mobileNav {
  @include grid-standard;
  position: relative;
  padding-bottom: 20px;
  z-index: 1;
  background: white;

  @include md {
    display: none;
  }
}
.mobileNavIsOpen {
  background: none;
  z-index: 1000;
  .mobileNavLogo {
    display: none;
  }
}
.mobileNavLogo {
  padding-top: 20px;
  // padding-left: 8%;
  // padding-right: 8%;
  grid-column-start: 2;
  grid-column-end: -2;
}
.mobileNavHamburger {
  z-index: 101;
  position: absolute;
  top: 20px;
  right: 20px;
}

.logo {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
.hamburger {
  position: absolute;
  top: 20px;
  right: 20px;
}

.link {
  @include unstyled-link();
}
.sidebarContainer {
  display: none;
  @include md {
    grid-column-start: 1;
    grid-column-end: 1;
    margin-left: calc(50% + rem(5));
    display: block;
    position: relative;
  }
  // background: green;
}
.sidebar {
  @include md {
    @include side-slug;

    width: max-content;
    text-transform: uppercase;
    position: relative;
    height: 100%;
    // width: 100%;
    // transform: rotate(90deg);
  }
}
.sidebarSpan {
  display: block;
  white-space: nowrap;
  // height: 100%;
  // width: 100%;
  position: absolute;
  // background: var( --color-red);
  top: 50%;
  // left: 50%;
  left: rem(8);
  transform: bottom left;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
.logoContainerEye {
  display: none;
  @include md {
    display: block;
    pointer-events: all;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    line-height: 0;
    height: rem(101);
    margin-top: rem(31);
    margin-left: rem(23);

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // on mount we want both logos out of view
    transform: translateX(-300px);
  }
}
.logoLink {
  display: block;
  // width: 100%;
  height: 100%;
}

.toggleContainer {
  pointer-events: all;
  position: fixed;
  position: absolute;
  top: var(--page-gutter);
  right: 0px;
  align-self: flex-end;
  z-index: 102;
}

.overlay {
  background-color: transparent;
}
