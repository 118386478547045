.video-js {
  background-color: transparent;
  video {
    // safari didn't seem to have the same dimensions as chrome (few pixels diff)
    // use "cover" to mask that bug?
    object-fit: cover;
  }
  &:focus,
  &:hover {
    @media (hover: hover) {
      .vjs-big-play-button {
        background-color: transparent;
        transition: none;
      }
    }
  }
  .vjs-big-play-button {
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    font-size: rem(100);
  }
  .vjs-poster.element-child {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .vjs-big-play-button {
    background-color: transparent;
    transition: none;

    .vjs-icon-placeholder::before {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .vjs-control-bar {
    background-color: transparent;
    // bottom: calc(-1rem - 2px);
    bottom: 0;
  }

  .vjs-slider {
    background-color: #860e0f; //transparentize(#fff, 0.5);
  }
  .vjs-progress-control .vjs-progress-holder {
    margin: 0;
    background: #860e0f;
  }
  .vjs-load-progress {
    background-color: #860e0f; //transparentize(#fff, 0.5);

    div {
      background-color: #860e0f; //transparentize(#fff, 0.5);
    }
  }

  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 20px;
    line-height: 1.67;
  }

  .vjs-play-progress {
    background-color: #ff0302;
    position: relative;
    &:before {
      background-color: #ff0302;
      // height: 3px;
      width: 0px;
      bottom: 0;
      right: -6px;
      top: 0;
      content: '';
    }
  }
  .vjs-time-tooltip {
    display: none;
    opacity: 0;
    width: 0;
  }

  &.vjs-ended {
    .vjs-progress-control {
      opacity: 0 !important;
    }
  }
  .vjs-mouse-display {
    width: 10px;
  }

  .vjs-progress-holder {
    transform-origin: bottom;
    height: 10px;
    // @include md {
    height: 0.3em;
    // }
  }
  .vjs-progress-control:hover .vjs-progress-holder {
    transform: scaleY(4);
    font-size: 1em;
  }
  .vjs-progress-control {
    align-items: flex-end;
  }
  .vjs-progress-control .vjs-mouse-display {
    width: 2px;
  }

  .vjs-control-bar {
    height: 10px;
  }
  .vjs-volume-panel {
    position: absolute;
    right: 5px;
    bottom: 40px;
  }

  .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover {
    width: auto;
    // bottom: 100px;
    // transition: none;
  }
  .vjs-volume-control {
    display: none;
  }

  .vjs-mute-control {
    .vjs-icon-placeholder {
      &::before {
        width: 20px;
        height: 20px;
        content: url("data:image/svg+xml,%3Csvg  viewBox='0 0 95 95' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.1927 29.8484L16 32.1358V62.6354L36.1927 65.1516L68 91V4L36.1927 29.8484Z' fill='white'/%3E%3C/svg%3E");
      }
    }
    &.vjs-vol-0 {
      .vjs-icon-placeholder {
        &::before {
          width: 20px;
          height: 20px;
          content: url("data:image/svg+xml,%3Csvg  viewBox='0 0 95 95' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M68.4236 3.27484L36.7547 29.2098L15.6420 32.1223V62.4226L68.4227 24.3873L68.4236 3.27484Z' fill='white'/%3E%3Cpath d='M68.4228 41.6777L90.2628 25.6598L84.7139 18.1986L8 74.4375L13.5489 81.9877L36.8467 65.8824L68.4228 91.7246V41.6777Z' fill='white'/%3E%3C/svg%3E");
        }
      }
    }
  }
}
