.flickity-page-dots {
  position: relative;
  width: 100%;
  height: 100px;
  // height: 25px;
  //   background: var( --color-red);
  bottom: 0;
  padding: 0;
  margin: 0;
  margin-top: rem(40);
  list-style: none;
  text-align: center;
  line-height: 1;
  // transform: scaleY(0.01);
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  margin: 0;
  border-radius: 0;
  opacity: 100%;
  display: inline-block;
  // width: 25%;
  height: 2px;
  background: #e0e0e0;
  cursor: pointer;
  transition: 0.25s;

  transform-origin: bottom left;
  padding-left: 10%;
  &::before {
    // opacity: 0;
    content: '';
    width: 100%;
    border-radius: 0;
    background-color: transparent;
  }

  &:only-child {
    display: none;
  }

  @include md {
    height: 1px;
  }
}

.flickity-page-dots .dot.is-selected {
  height: 2px;
  background: black;
  @include md {
    height: 1px;
  }
}

.flickity-enabled {
  .flickity-page-dots {
    visibility: hidden;
  }
  &.show-controls {
    .flickity-page-dots {
      visibility: visible;
    }
  }
}
