@import '../scss/components_base';

.pageContainer {
  @include md {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}
// .logo {
//   padding: var(--logo-padding);
// }
.page {
  display: grid;
  @include md {
    display: block;
    flex: 1;
  }
}

.mobileSidebar {
  grid-row-start: 1;
  @include md {
    display: none;
  }
}
.appInner {
  grid-row-start: 1;
}

.footer {
  @include md {
  }
}
.selectorMenuContainer {
  display: none;
  @include md {
    display: block;
  }
}

.fadeOverlay {
  z-index: 9000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  @include md {
    // stay under the sidebar
    z-index: 2;
  }
}
