@import '../../scss/components_base';

.menuContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}
.bg {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--color-dark-red);
  width: 100%;
  min-height: 100%;
  z-index: 1;
}
.eyeLogo {
  z-index: 2;
  height: 300px;
  padding-top: 16px;
  padding-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    path {
      fill: var(--color-red);
    }
  }
}
.list {
  @include list-unstyled;
  @include gutter;
  padding-top: 10px;
  flex: 1;
  position: relative;
  z-index: 2;
  width: 100%;
  user-select: none;
}
.listItem {
  user-select: none;
}

.listItemLink {
  @include unstyled-link();
  text-transform: uppercase;
  display: block;
  width: 100%;
  text-align: center;
  font-family: var(--font-primary-alt1);
  font-style: normal;
  font-weight: 600;
  font-size: 113px;
  line-height: 0.75;
  letter-spacing: 0.374058px;
  color: var(--color-red);
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  &:focus {
    outline: none;
  }
  @media (hover: hover) {
    &:hover {
      color: var(--color-rollover);
    }
  }
}
.listItemLinkActive {
  color: #660001;
  user-select: none;
  @include md {
    color: var(--color-rollover);
  }
}
