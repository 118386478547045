@import '../../scss/components_base';
.selectorContainer {
  position: fixed;
  top: 0; //calc((100vw - var(--logo-padding) * 2) * var(--logo-ratio) + var(--logo-padding) * 2);
  left: 0;
  width: var(--vw);

  // z-index: 1;
  transform: translate3d(0, 0, 0, 0);
}
.animationWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
.logo {
  padding: var(--logo-padding);
}
.menuList {
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
  justify-content: flex-end;

  justify-self: flex-end;
  justify-items: flex-end;
  align-content: flex-end;
}
