@import '../../../scss/components_base';

.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: white;
  z-index: 8;
  transform: translateY(-60px);
  transition: transform 0.5s;
  @include md {
    display: none;
  }
}
.containerIsVisible {
  transform: translateY(0);
}
