@import '../../../scss/components_base';

.container {
  width: 21px;
  height: 10px;
  position: relative;

  transition: 0.15s ease-in-out;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      .line {
        background: var(--color-rollover);
      }
      .lineActive {
        background: var(--color-rollover);
      }
    }
  }
}
.containerActive {
  transform: translate(0px, 8px);
}
.line {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: var(--logo-color);
  left: 0;
  top: 0;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;

  &:nth-child(2) {
    transform: translate(0px, 6px);
  }
}

.lineActive {
  background: var(--color-red);

  &:nth-child(1) {
    transform: rotate(135deg); // ;
  }

  &:nth-child(2) {
    transform: rotate(-135deg);
  }
}
