// Base styles that aren't a component, a utility, a reset thing, etc...

html {
  background: white;
  color: black;
  overscroll-behavior: none;
  scroll-behavior: auto;
  @media (prefers-reduced-motion: no-preference) {
  }
  @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001ms !important;
    }
  }
}
body {
  opacity: 0;
  transition: opacity 0.1s;
  overscroll-behavior: none;
  background: #ffffff;
  &.is-fonts-loaded {
    opacity: 1;
  }
}
abbr {
  text-decoration: none;
  border-bottom: none;
}

button,
a {
  &:focus {
    outline: 0;
  }
}
//
.pageNotFound__container {
  background: white;
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.pageNotFound__title {
  padding: rem(28);
}
.pageNotFound__footer {
  position: absolute;
  z-index: 11;
  width: 100%;
  bottom: 0;
}
